import React from 'react';

const SectionHeading = ({ children }) => (
  <div className="section-heading">
    <hr className="section-heading__line" />
    <h2 className="section-heading__text">{children}</h2>
  </div>
);

export default SectionHeading;
