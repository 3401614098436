import React from 'react';

import SectionHeading from './SectionHeading';
import WorkGrid from './WorkGrid';
import WorkCard from './WorkCard';

import SubsplashWebTools from '../images/work/subsplash-web-tools/1.png';
import COTR from '../images/work/cotr/1.png';
import SubsplashDashboard from '../images/work/subsplash-dashboard/1.png';
import PigBarber from '../images/work/pig-barber/1.png';
import Sentinel from '../images/work/sentinel/1.png';

const Work = () => (
  <div id="work">
    <SectionHeading>Work</SectionHeading>

    <WorkGrid>
      <WorkCard
        title="Sentinel"
        subtitle="UI, UX, direction, front-end development"
        start="1"
        end="6"
        image={Sentinel}
      />

      <WorkCard
        title="Church on the Ridge"
        subtitle="Ideation, consulting, design"
        start="6"
        end="10"
        image={COTR}
      />
      <WorkCard
        title="Subsplash® Web Tools"
        subtitle="Technical architecture, front-end development"
        start="1"
        end="4"
        image={SubsplashWebTools}
      />

      <WorkCard
        title="Subsplash® Dashboard"
        subtitle="Technical architecture, front-end development"
        start="4"
        end="10"
        image={SubsplashDashboard}
      />

      <WorkCard
        title="Pig Barber"
        subtitle="Front-end development"
        start="1"
        end="10"
        image={PigBarber}
      />
    </WorkGrid>
  </div>
);

export default Work;
