import React from 'react';

const cardStyle = ({ start = 1, end = 10, height = 500 }) => {
  return {
    gridColumn: `${start} / ${end}`,
    height: height
  };
};

const WorkCard = ({ title, subtitle, image, ...rest }) => (
  <div className="work-card" style={cardStyle(rest)}>
    <div
      className="work-card__image"
      style={{ backgroundImage: `url(${image})` }}
    />
    <div className="work-card__titles">
      <h3 className="work-card__title">{title}</h3>
      <h4 className="work-card__subtitle">{subtitle}</h4>
    </div>
  </div>
);

export default WorkCard;
