import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';

import Work from '../components/Work';

const WorkPage = ({ location }) => (
  <Layout scroll currentPath={location.pathname}>
    <SEO
      title="Brian Kulp Co. | Work"
      keywords={[`brian kulp`, `seattle`, `designer`, `developer`]}
    />
    <Work />
  </Layout>
);

export default WorkPage;
